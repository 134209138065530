<template>
  <div>
    <!-- select 2 demo -->

    <b-modal id="modal-user" title="New Admin User" centered ok-only size="sm" no-close-on-backdrop hide-footer="true">
      <b-form-group label="Name:" invalid-feedback="Name is required." ref="name">
        <b-form-input id="name-input" ref="name" placeholder="Enter name here." required v-model="user.name" />
      </b-form-group>

      <b-form-group label="Email:" invalid-feedback="Email is required." ref="email">
        <b-form-input id="name-input" ref="email" placeholder="Enter email here." required v-model="user.email" />
      </b-form-group>

      <b-form-group label="Password:" invalid-feedback="Password is required." ref="pass">
        <b-form-input id="name-input" ref="pass" type="password" placeholder="Enter password here." required
          v-model="user.password" />
      </b-form-group>
      <b-button class="float-right mt-2 ml-5" variant="primary" @click="CreateUser()" :disabled="saving">
        <b-spinner v-if="saving" variant="light" type="grow" small label="Spinning"></b-spinner>
        <span v-else class="text-nowrap">Save</span>
      </b-button>
    </b-modal>

    <b-modal id="modal-rights" title="User Rights" centered ok-only size="sm" no-close-on-backdrop hide-footer="true">
      <b-form-group label="Forms" invalid-feedback="Form is required." ref="categories">
        <v-select :closeOnSelect="false" v-model="selectedRoles" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name" ref="categories" multiple placeholder="Select forms" :options="roles" :reduce="(x) => x.id" />
      </b-form-group>
      <b-button class="float-right mt-2 ml-5" variant="primary" @click="UpdateRights()" :disabled="request">
        <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
        <span v-else class="text-nowrap">Save</span>
      </b-button>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal()" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </div>
              <!-- <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(image)="data">
            <b-img :src="data.value" style="width: 100px; height: 75px" rounded alt="Rounded image" />
          </template>
          <template #cell(actions)="data">
            <!-- 
              <b-button
                title="Change Password"
                style="margin-left:5px;"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                @click="ResetPassword(data.item)"
              >
                Reset Password
              </b-button>
              -->
            <b-button title="User Rights" style="margin-left: 5px" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary" class="mr-50 mt-50 btn-icon" @click="ChangeRights(data.item)">
              User Rights
            </b-button>
            <b-button v-if="data.item.status == 'active'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger" class="mr-50 mt-50 btn-icon" v-b-tooltip.hover.v-danger v-b-tooltip.placement.right
              title="Suspend" size="16" @click="changeStatus(data.item, 'suspend')">
              <feather-icon size="16" icon="XIcon" />
            </b-button>
            <b-button v-else v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success"
              class="mr-50 mt-50 btn-icon" v-b-tooltip.hover.v-success v-b-tooltip.placement.right title="Activate"
              size="16" @click="changeStatus(data.item, 'activate')">
              <feather-icon size="16" icon="CheckIcon" />
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" class="mr-50 mt-50 btn-icon"
              v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete" size="16"
              @click="DeleteFlavour(data.item.id)">
              <feather-icon size="16" icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BBadge,
  BPagination,
  BFormGroup,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    //Multiselect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BSpinner,
    BImg,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    /* modalFilter: {
        type: [String, null],
        default: null,
        },
        modalFilterOptions: {
        type: Array,
        required: true,
        }*/
  },
  created() {
    this.LoadData();
    this.LoadRoles();
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD"),
      categories: [],
      plus:
        "https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png",
      fileProfile: "",
      logoloading: false,
      errors: {
        image: false,
        validityDate: false,
      },
      statusOptions: ["Active", "Inactive"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "name", key: "name" },
        { label: "email", key: "email" },
        "actions",
      ],
      items: [], //{ name: 'gg', id: 0 }
      subCatItems: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      user: {
        name: "",
        email: "",
        password: "",
        role: "admin",
      },
      selectedRoles: [],
      roles: [],
      selectedUser: 0,
      detailObj: {},
      request: false,
      saving: false,
    };
  },
  methods: {
    CreateUser() {
      if (
        this.user.name.trim() == "" ||
        this.user.email.trim() == "" ||
        this.user.password.trim() == ""
      ) {
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.user);
        this.saving = true;
        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.geotalent.co/api/Tokens/Register",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.user,
        };
        axios(config)
          .then((response) => {
            if (response.data.status === "success") {
              this.$bvToast.toast("New user added.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
              this.$bvModal.hide("modal-user");
              this.LoadData();
              this.saving = false;
            }
          })
          .catch(function(error) {
            console.log(error);
            this.saving = false;
          });
      }
    },
    ChangeRights(item) {
      this.selectedUser = item.id;
      console.log(this.selectedUser);
      // this.$bvModal.show('modal-rights');

      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/users/LoadRights?uID=" + item.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((response) => {
          console.log(response);
          if (response.data.status === "success") {
            this.selectedRoles = response.data.data;
            this.$bvModal.show("modal-rights");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    UpdateRights() {
      if (this.selectedRoles.length > 0) {
        this.request = true;
        var arr = [];
        this.selectedRoles.forEach((item) => {
          var data = {
            userID: this.selectedUser,
            roleID: item,
          };
          arr.push(data);
        });
        console.log(arr);

        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.geotalent.co/api/Users/SaveRights",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: arr,
        };
        axios(config)
          .then((response) => {
            console.log("res", response);
            this.request = false;
            if (response.data.status === "success") {
              this.$bvToast.toast("User rights updated.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-bottom-center",
              });
              this.$bvModal.hide("modal-rights");
              this.selectedUser = 0;
            }
          })
          .catch(function(error) {
            console.log(error);
            this.request = false;
          });
      } else {
        this.$bvToast.toast("Please assign the rights.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      }
    },
    CheckImage() {
      if (this.myObj.image == "") {
        return (this.errors.image = true);
      } else {
        // elem.state = true;
        return (this.errors.image = false);
      }
    },
    CheckDate() {
      console.log(this.currentDate);
      if (
        this.myObj.validityDate <= this.currentDate ||
        this.myObj.validityDate == null
      ) {
        return (this.errors.validityDate = true);
      } else {
        // elem.state = true;
        return (this.errors.validityDate = false);
      }
    },
    OpenAddModal() {
      this.user = {
        id: 0,
        name: "",
        email: "",
        password: "",
        role: "admin",
        status: "active",
      };
      this.$bvModal.show("modal-user");
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.bannerId = row.bannerId;
      this.myObj.image = row.image;
      this.myObj.categoryId = row.categoryId;
      this.myObj.validityDate = row.validityDate;
      this.plus = row.image;
      this.logoloading = "loaded";
      console.log(this.plus);
      console.log(this.myObj);
    },

    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require("axios");
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            this.myObj.image = this.plus;
            //
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = "";
      this.logoloading = false;
    },
    changeStatus(obj, text) {
      Swal.fire({
        title: "Are you sure?",
        text: "This will " + text + " the user",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, " + text + " it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (text == "activate") {
            obj.status = "active";
          } else {
            obj.status = "suspended";
          }
          console.log(obj);
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.geotalent.co/api/Users/" + obj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: obj,
          };
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.LoadData();
                this.$bvToast.toast("User is " + text + " now.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Users/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.LoadData();
                Swal.fire("Success!", "User has been deleted.", "success");
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Users", //LoadData?role=admin&status=
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log('res', response.data);
          this.items = response.data.data;
          this.items = this.items.filter((el) => el.role == "admin");

          console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadRoles() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Users/LoadRoles",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log('res', response.data);
          this.roles = response.data.data;

          console.log(this.roles);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
